<template>
  <div>
    <b-navbar-nav class="nav">
      <b-nav-item-dropdown
        menu-class="dropdown-menu-media"
      >
        <template #button-content>
          <b-button
            size="sm"
            variant="warning"
          >
            <feather-icon
              icon="CpuIcon"
              class="mr-50"
            />
            <span class="align-middle">Công tơ điện tử</span>
          </b-button>
        </template>
        <b-card
          :img-src="require('@/assets/images/illustration/meter.svg')"
          img-alt="Công tơ điện tử"
          img-top
          class="shadow-none font-weight-normal my-0 py-0"
        >

          <b-card-text class="">
            Công tơ điện tử giúp bạn ghi chỉ số dễ dàng:
          </b-card-text>
          <ul class="colors-list list-unstyled mb-0">
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Tự động đọc ghi chỉ số từ xa</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Số liệu được cập nhật thời gian thực</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Điều khiển tắt/bật từ xa</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Hỗ trợ công tơ Tuya/VConnex</span>
            </li>
          </ul>
          <b-button
            variant="outline-info"
            class="mt-1"
            role="link"
            href="https://zalo.me/4210535441806653975"
            target="_blank"
          >
            Liên hệ tích hợp
          </b-button>
        </b-card>

      </b-nav-item-dropdown>
      <b-nav-item-dropdown menu-class="dropdown-menu-media">
        <template #button-content>
          <b-button
            size="sm"
            variant="danger"
          >
            <feather-icon
              icon="CreditCardIcon"
              class="mr-50"
            />
            <span class="align-middle">Gạch nợ tự động</span>
          </b-button>
        </template>
        <b-card
          :img-src="require('@/assets/images/illustration/tingee.svg')"
          img-alt="Gạch nợ tự động"
          img-top
          class="shadow-none font-weight-normal my-0 py-0"
        >

          <b-card-text class="">
            Thanh toán gạch nợ tự động trong vòng 10s:
          </b-card-text>
          <ul class="colors-list list-unstyled mb-0">
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Tiền về trực tiếp tài khoản chủ nhà, ngay lập tức</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Mỗi hóa đơn có mã QRCode để thanh toán</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Khách thuê quét QRCode và hiển thị sẵn STK, số tiền, nội dung</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Ngay sau khi khách thuê thanh toán, trong vòng 10s sẽ gach nợ tự động và thông báo cho chủ nhà + khách thuê</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Chi phí siêu rẻ so với giá trị mang lại, chỉ 1.000 đ / giao dịch gạch nợ</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Thời gian tích hợp nhanh chóng, sẵn sàng trong 10 phút</span>
            </li>
          </ul>
          <b-button
            variant="outline-danger"
            class="mt-1"
            target="_blank"
            href="https://www.youtube.com/watch?v=YdzTqzWr0ig"
            role="link"
          >
            Xem video demo
          </b-button>
        </b-card>

      </b-nav-item-dropdown>
      <b-nav-item-dropdown menu-class="dropdown-menu-media">
        <template #button-content>
          <b-button
            size="sm"
            variant="info"
          >
            <feather-icon
              icon="MessageCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Zalo BOT nhắn tin</span>
          </b-button>
        </template>
        <b-card
          :img-src="require('@/assets/images/illustration/zalobot.svg')"
          img-alt="Zalo BOT nhắn tin"
          img-top
          class="shadow-none font-weight-normal my-0 py-0"
        >

          <b-card-text class="">
            BOT Zalo nhắn tin thông báo tới khách thuê:
          </b-card-text>
          <ul class="colors-list list-unstyled mb-0">
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Tự động nhắn tin đến SĐT Zalo của khách thuê</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Gửi thông báo hóa đơn, nhắc nợ, xác nhận thanh toán...</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Có thể sử dụng Zalo hotline của chủ nhà để nhắn tin</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Gửi kèm cả hình ảnh hóa đơn, mã QRCode thanh toán tiện lợi</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Chi phí siêu rẻ với 400đ/tin</span>
            </li>
          </ul>
          <b-button
            variant="outline-info"
            class="mt-1"
            role="link"
            href="https://zalo.me/4210535441806653975"
            target="_blank"
          >
            Liên hệ tìm hiểu
          </b-button>
        </b-card>

      </b-nav-item-dropdown>
      <b-nav-item-dropdown menu-class="dropdown-menu-media">
        <template #button-content>
          <b-button
            size="sm"
            variant="secondary"
          >
            <feather-icon
              icon="CreditCardIcon"
              class="mr-50"
            />
            <span class="align-middle">Đồng bộ PM kế toán Misa Amis</span>
          </b-button>
        </template>
        <b-card
          :img-src="require('@/assets/images/banner/misa-amis.jpeg')"
          img-alt="Zalo BOT nhắn tin"
          img-top
          class="shadow-none font-weight-normal my-0 py-0"
        >

          <b-card-text class="">
            Đưa dữ liệu tự động từ Resident vào PM Kế toán Misa:
          </b-card-text>
          <ul class="colors-list list-unstyled mb-0">
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Đồng bộ hóa đơn</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Đồng bộ thu chi</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Đồng bộ thông tin hợp đồng</span>
            </li>
            <li class="mt-50">
              <feather-icon
                size="15"
                icon="ChevronsRightIcon"
              />
              <span>Đồng bộ thông tin khách hàng</span>
            </li>
          </ul>
          <b-button
            variant="outline-primary"
            class="mt-1"
            role="link"
            href="https://zalo.me/4210535441806653975"
            target="_blank"
          >
            Liên hệ tìm hiểu
          </b-button>
        </b-card>

      </b-nav-item-dropdown>
    </b-navbar-nav>

  </div>

</template>

<script>
import {
  BNavbarNav,
  BNavItemDropdown,
  BButton,
  BCard, BCardText,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BButton,
    BCard,
    BCardText,
  },
  setup() {
    const bookmarks = ref([
      {
        title: 'Sơ đồ tòa nhà',
        icon: 'GridIcon',
        route: 'shortcuts',
        isBookmarked: true,
      },
      {
        title: 'Chat',
        route: { name: 'apps-chat' },
        icon: 'MessageSquareIcon',
        isBookmarked: true,
      },
      {
        title: 'Todo',
        route: { name: 'apps-todo' },
        icon: 'CheckSquareIcon',
        isBookmarked: true,
      },

    ]);
    const currentSelected = ref(-1);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const toggleBookmarked = item => {
      // Find Index of item/page in bookmarks' array
      const pageIndexInBookmarks = bookmarks.value.findIndex(
        i => i.route === item.route,
      );

      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks.value[pageIndexInBookmarks].isBookmarked = false;
        bookmarks.value.splice(pageIndexInBookmarks, 1);
      } else {
        bookmarks.value.push(item);
        bookmarks.value[bookmarks.value.length - 1].isBookmarked = true;
      }
    };

    return {
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      toggleBookmarked,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}

</style>
