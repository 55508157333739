<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="isVMarket ? 'https://vmarket.vn' : 'https://resident.vn'"
        target="_blank"
      >{{ appName }}</b-link>
    </span>

    <b-button
      v-if="appName !== 'TingTong'"
      class="float-md-right d-none d-md-block ml-50"
      variant="outline-warning"
      href="https://zalo.me/4210535441806653975"
      target="_blank"
      size="sm"
    >
      <feather-icon
        icon="LifeBuoyIcon"
        class="mr-50"
      />
      <span class="align-middle">Zalo hỗ trợ</span>
    </b-button>

    <b-button
      v-if="appName === 'TingTong'"
      class="float-md-right d-none d-md-block"
      variant="outline-danger"
      href="tel://0355430074"
      size="sm"
    >
      <feather-icon
        icon="PhoneIcon"
        class="mr-50"
      />
      <span class="align-middle">035.543.0074</span>
    </b-button>
    <b-button
      class="float-md-right d-none d-md-block mr-50"
      variant="outline-success"
      :href="isVMarket ? 'https://docs.vmarket.vn' : 'https://docs.resident.vn'"
      target="_blank"
      size="sm"
    >
      <feather-icon
        icon="BookOpenIcon"
        class="mr-50"
      />
      <span class="align-middle">Hướng dẫn sử dụng</span>
    </b-button>
  </p>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BLink,
    BButton,
  },
  setup() {
    const { appName } = $themeConfig.app;
    const isVMarket = process.env.VUE_APP_PROVIDER === 'vmarket';

    return {
      appName,
      isVMarket,
    };
  },
};
</script>
