<template>
  <div>
    <layout-vertical>
      <router-view />

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </template>
      <template
        slot="footer"
      >
        <app-footer />
      </template>

      <app-customizer
        v-if="showCustomizer"
        slot="customizer"
      />

    </layout-vertical>

  </div>

</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue';
import { $themeConfig } from '@themeConfig';
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import useJwt from '@/auth/jwt/useJwt';

import Navbar from '../components/Navbar.vue';
import AppFooter from '../components/AppFooter.vue';

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    Navbar,
    AppFooter,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      showGallery: $themeConfig.layout.gallery,
    };
  },
  created() {
    this.checkNotificationPermission();
  },
  methods: {
    checkNotificationPermission() {
      const messaging = getMessaging();
      if (Notification.permission === 'granted') {
        getToken(messaging, { vapidKey: process.env.VUE_APP_VAPIDKEY }).then(currentToken => {
          if (currentToken) {
            useJwt.firebaseToken(currentToken);
          }
        }).catch(() => {

        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission => {
          if (permission) {
            getToken(messaging, { vapidKey: process.env.VUE_APP_VAPIDKEY }).then(currentToken => {
              if (currentToken) {
                useJwt.firebaseToken(currentToken);
              }
            }).catch(() => {

            });
          }
        }));
      } else {
      //

      }
    },
  },
  setup() {
    const toastification = useToast();

    const messaging = getMessaging();

    onMessage(messaging, payload => {
      const { notification } = payload;
      const { title, body } = notification;

      toastification({
        component: ToastificationContent,
        props: {
          title,
          text: body,
          icon: 'BellIcon',
          variant: 'primary',
        },

      }, { position: 'bottom-right' });
    });

    return {

    };
  },
};
</script>
